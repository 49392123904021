.samir-bio-container {
  display: flex;
  margin: 10px;
  flex-direction: column;
  max-width: 800px;
  justify-content: center;
  color: black;
  margin: 0 auto;
}

.samir-bio-title {
  font-size: 36px;
  margin-top: 40px;
  text-align: center;
}

.awards-title {
  text-align: center;
  padding: 10px;
  font-size: 36px;
}

.samir-bio-text {
  font-size: 20px;
  margin-top: -5px;
  text-align: justify;
}

.award-item {
  font-size: 20px;
  padding: 10px;
  color: black;
} 


.award-item-link:hover {
  color: #4e2a84;
}

.award-item-link {
  color: black;
  font-size: 20px;
}

.award-item-with-link {
  padding: 10px;
}

.awards-container {
  margin-top: -20px;
  text-align: center;
}

.first-two-awards {
  text-align: center;
}