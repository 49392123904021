.nav {
  background-color: #4e2a84;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px;
}

.nav a {
  margin: 0 10px;
  color: white;

  font-size: 22px;
}

.nav a:hover {
  color: lightgrey;
  text-decoration: none;
}

.dropdown-item {
  background-color: #4e2a84 !important;
  margin: 0 !important;
}

.dropdown-item:hover {
  background-color: lightgray !important;
  color: white !important;
}

div.dropdown-menu.show {
  padding: 0;
}

.nav-link {
  color: white !important;

  font-size: 24px;
}

.khuller-image {
  max-width: 120px;
  border-radius: 5px;
  height: auto;
  margin-top: 8px;
  margin-bottom: -2px;
}

.nav-bar-header-wrapper {
  display: flex;
  flex-flow: row;

  padding: 10px;
  align-items: center;
  justify-content: center;
  /* margin: 0 220px; */
}

.nav-bar-header-wrapper div {
  margin: 0 5px;
}

@media (max-width: 530px) {
  .position1 {
    max-width: 200px;
  }
}

.title-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.name {
  font-size: 36px;
  margin-left: 2px;
}

.position1 {
  font-size: 18px;
  margin-left: 2px;
}

.position2 {
  font-size: 18px;
  margin-left: 2px;
}

.school {
  font-size: 22px;
  margin-left: 2px;
}

#basic-nav-dropdown {
  margin: 0 10px;
  padding: 0;
}

@media (max-width: 850px) {
  .nav {
    flex-direction: column;
    align-items: flex-start;
  }
}
