.publications-container {
  display: flex;
  justify-content: center;
  margin: 10px;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
}

.pub-link {
  color: black;
}

.pub-link:hover {
  color: #4e2a84;
}

.pub-wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.pub-title {
  font-size: 36px;
  color: black;
  text-align: center;
  margin-top: 40px;
}

.title-wrapper {
  margin: 0 auto;
} 
