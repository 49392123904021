.home-container {
  display: flex;
  justify-content: center;
  margin: 10px;
  flex-direction: column;
}

.home-title {
  font-size: 36px;
  color: black;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.blurb-container {
  display: flex;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 10px;
}
.blurb {
  font-size: 20px;
  color: black;
  text-align: justify;
  justify-self: center;
}

.contact {
  font-size: 20px;
  color: black;
  text-align: center;
  padding-bottom: 10px;
}

.contact-container {
  display: flex;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 10px;
}
