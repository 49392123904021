.course-link {
  color: black;
}

.course-link:hover {
  color: #4e2a84;
}

.teaching-title {
  font-size: 36px;
  color: black;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.teaching-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.grid-container {
  border: 1px solid #4e2a84;
  max-width: 800px;
}

.grid-item-container {
  display: grid;
  grid-template-columns: 140px auto;

  margin: auto;
}

.grid-item {
  background-color: white;
  border: 1px solid #4e2a84;
  padding: 20px;
  font-size: 16px;
  text-align: left;
}

.grid-item-container:first-child * {
  font-weight: bold;
}

#grid-title {
  font-weight: bold;
}
